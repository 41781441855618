// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-diensten-js": () => import("./../../../src/pages/diensten.js" /* webpackChunkName: "component---src-pages-diensten-js" */),
  "component---src-pages-projecten-js": () => import("./../../../src/pages/projecten.js" /* webpackChunkName: "component---src-pages-projecten-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-contact-template-js": () => import("./../../../src/templates/contact-template.js" /* webpackChunkName: "component---src-templates-contact-template-js" */),
  "component---src-templates-dienst-template-js": () => import("./../../../src/templates/dienst-template.js" /* webpackChunkName: "component---src-templates-dienst-template-js" */),
  "component---src-templates-index-template-js": () => import("./../../../src/templates/index-template.js" /* webpackChunkName: "component---src-templates-index-template-js" */),
  "component---src-templates-project-template-js": () => import("./../../../src/templates/project-template.js" /* webpackChunkName: "component---src-templates-project-template-js" */),
  "component---src-templates-tags-template-js": () => import("./../../../src/templates/tags-template.js" /* webpackChunkName: "component---src-templates-tags-template-js" */)
}

